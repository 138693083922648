import React, { useState, useEffect } from "react";
import {
  ArrowUp,
  ArrowDown,
  Users,
  PlayCircle,
  MousePointer,
  Mail,
  Lock,
  Star,
  Zap,
} from "lucide-react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { fetchProducts, getUserBrandName } from "../../Utils";
import { auth } from "../../firebase-config";

const MySwal = withReactContent(Swal);

const analyticsData = [
  { title: "Total Plays", value: 1200, icon: PlayCircle, change: 5.2 },
  { title: "Total Clicks", value: 800, icon: MousePointer, change: -2.1 },
  { title: "Retarget Emails Sent", value: 500, icon: Mail, change: 3.8 },
  { title: "New Users Collected", value: null, icon: Users, locked: true },
];

const AnalyticsCard = ({ title, value, icon: Icon, change, locked }) => {
  const handleAdvancedFeatureClick = () => {
    MySwal.fire({
      title: "Coming Soon!",
      text: "This feature will be available soon.",
      icon: "info",
      confirmButtonText: "OK",
      customClass: {
        popup: "swal2-popup",
        title: "swal2-title",
        content: "swal2-content",
        confirmButton: "swal2-confirm",
      },
    });
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "0.5rem",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "1.5rem",
        transition: "all 0.3s",
        opacity: locked ? 0.7 : 1,
      }}
      onMouseOver={(e) =>
        (e.currentTarget.style.boxShadow = "0 10px 15px rgba(0, 0, 0, 0.1)")
      }
      onMouseOut={(e) =>
        (e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.1)")
      }
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <div style={{ color: "#6B7280" }}>{title}</div>
        <Icon color="#3B82F6" size={24} />
      </div>
      {locked ? (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: "#6B7280",
              backgroundColor: "#F3F4F6",
              padding: "0.5rem",
              borderRadius: "0.25rem",
              cursor: "pointer",
            }}
            onClick={handleAdvancedFeatureClick}
          >
            <Lock size={16} color="#3B82F6" />
            <span
              style={{
                marginLeft: "0.25rem",
                color: "#3B82F6",
                fontWeight: "bold",
              }}
            >
              Advanced Plan Feature
            </span>
          </div>
        </div>
      ) : (
        <>
          <div
            style={{
              fontSize: "1.875rem",
              fontWeight: "bold",
              marginBottom: "0.5rem",
            }}
          >
            {value.toLocaleString()}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              color: change >= 0 ? "#10B981" : "#EF4444",
            }}
          >
            {change >= 0 ? <ArrowUp size={16} /> : <ArrowDown size={16} />}
            <span style={{ marginLeft: "0.25rem" }}>{Math.abs(change)}%</span>
          </div>
        </>
      )}
    </div>
  );
};

const TopProductsCard = ({ products }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "0.5rem",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "1.5rem",
        gridColumn: "span 2",
      }}
    >
      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          marginBottom: "1.5rem",
          textAlign: "center",
          color: "#4B5563",
        }}
      >
        Top 3 Products by Plays
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
        }}
      >
        {products.map((product, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "30%",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                paddingBottom: "100%",
                marginBottom: "1rem",
                borderRadius: "50%",
                overflow: "hidden",
                boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                border: `4px solid ${
                  index === 0 ? "#FFD700" : index === 1 ? "#C0C0C0" : "#CD7F32"
                }`,
              }}
            >
              <img
                src={product.image}
                alt={product.title}
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
            <h3
              style={{
                fontSize: "1.1rem",
                fontWeight: "bold",
                marginBottom: "0.5rem",
                textAlign: "center",
                color: "#1F2937",
              }}
            >
              {product.title}
            </h3>
            <p
              style={{
                fontSize: "1rem",
                color: "#6B7280",
                textAlign: "center",
              }}
            >
              {product.totalPlays} plays
            </p>
            <a
              href={product.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: "0.875rem",
                color: "#3B82F6",
                textDecoration: "underline",
              }}
            >
              View Product
            </a>
            {index === 0 && <Star size={24} color="#FFD700" />}
          </div>
        ))}
      </div>
    </div>
  );
};

const TopPlaysCard = ({ products }) => {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "0.5rem",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        padding: "1.5rem",
        gridColumn: "span 2",
      }}
    >
      <h2
        style={{
          fontSize: "1.5rem",
          fontWeight: "bold",
          marginBottom: "1.5rem",
          textAlign: "center",
          color: "#4B5563",
        }}
      >
        Top 3 Plays
      </h2>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {products.map((product, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1rem",
              backgroundColor: "#F3F4F6",
              borderRadius: "0.5rem",
            }}
          >
            <div
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                marginRight: "1rem",
                color: "#3B82F6",
              }}
            >
              #{index + 1}
            </div>
            <img
              src={product.image}
              alt={product.title}
              style={{
                width: "50px",
                height: "50px",
                objectFit: "cover",
                borderRadius: "50%",
                marginRight: "1rem",
              }}
            />
            <div style={{ flex: 1 }}>
              <h3
                style={{
                  fontSize: "1.1rem",
                  fontWeight: "bold",
                  color: "#1F2937",
                }}
              >
                {product.title}
              </h3>
              <p style={{ fontSize: "0.875rem", color: "#6B7280" }}>
                {product.totalPlays} plays
              </p>
            </div>
            <a
              href={product.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                fontSize: "0.875rem",
                color: "#3B82F6",
                textDecoration: "underline",
              }}
            >
              View Product
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const Analytics = () => {
  const [topProducts, setTopProducts] = useState([]);

  useEffect(() => {
    const fetchTopProducts = async () => {
      const user = auth.currentUser;
      if (user) {
        const brandName = await getUserBrandName(user.email);
        if (brandName) {
          const products = await fetchProducts(brandName);
          const sortedProducts = products
            .sort((a, b) => b.totalPlays - a.totalPlays)
            .slice(0, 3);
          setTopProducts(sortedProducts);
        }
      }
    };

    fetchTopProducts();
  }, []);

  return (
    <div
      style={{
        padding: "2rem",
        backgroundColor: "#F3F4F6",
        minHeight: "100vh",
      }}
    >
      <h1
        style={{
          fontSize: "1.875rem",
          fontWeight: "bold",
          marginBottom: "2rem",
          color: "#1F2937",
        }}
      >
        Analytics Overview
      </h1>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "1.5rem",
        }}
      >
        {analyticsData.map((data, index) => (
          <AnalyticsCard key={index} {...data} />
        ))}
        <TopPlaysCard products={topProducts} />
        <TopProductsCard products={topProducts} />
      </div>
    </div>
  );
};

export default Analytics;
