import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ShoppingBag,
  Gift,
  Crown,
  Sparkles,
  Check,
  Menu,
  Play,
  ShoppingCart,
  Mail,
  Target,
  TrendingUp,
  Users,
  Smile,
  Zap,
  ArrowRight,
  Star,
} from "lucide-react";
import { auth } from "../../firebase-config";
import InfluencerOptIn from "../../Components/InfluencerOptIn/InfluencerOptIn";

const HomeAlt = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  const [rotatingText, setRotatingText] = useState("50k");
  const rotatingTexts = [
    "50k followers",
    "100k followers",
    "500k followers",
    "1 Million",
  ];

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % rotatingTexts.length;
      setRotatingText(rotatingTexts[currentIndex]);
    }, 1500); // Change text every 1.5 seconds (doubled speed)

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  const scrollToPricing = () => {
    const pricingSection = document.getElementById("pricing-section");
    if (pricingSection) {
      pricingSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleAuth = (type) => {
    navigate("/Auth", { state: { authType: type } });
  };

  const handleDashboard = () => {
    navigate("/Dashboard");
  };

  const GoldMineText = ({ children }) => (
    <span className="relative inline-block font-extrabold">
      <span className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-yellow-400 via-yellow-300 to-yellow-400 opacity-50 blur-sm"></span>
      <span className="relative goldText">{children}</span>
    </span>
  );

  const HighlightText = ({ children }) => (
    <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600 font-bold">
      {children}
    </span>
  );
  return (
    <div className="min-h-screen bg-gradient-to-br from-yellow-100 via-red-100 to-pink-100">
      {/* Navigation Bar */}
      <nav className="bg-white shadow-md">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex-shrink-0 flex items-center gap-5">
              <span role="img" aria-label="Penguin" className="text-2xl">
                🐧
              </span>
              <span
                style={{
                  fontFamily: '"Press Start 2P", cursive',
                  fontWeight: 400,
                  margin: "0 0 var(--space)",
                  textShadow: "2px 3px 0 orange",
                  display: "flex",
                  gap: "15px",
                  alignItems: "center",
                  fontSize: "20px", // Increased font size for Pikvin text
                }}
              >
                Pikvin
              </span>
            </div>
            <div className="hidden md:flex items-center space-x-4">
              <button className="text-gray-800 hover:text-red-600 px-3 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out ">
                Shop
              </button>
              <button className="text-gray-800 hover:text-red-600 px-3 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out">
                Play
              </button>
              <button
                onClick={scrollToPricing}
                className="text-gray-800 hover:text-red-600 px-3 py-2 rounded-lg text-sm font-semibold cursor-pointer custom-cursor transition-colors duration-300 ease-in-out"
              >
                Pricing
              </button>
            </div>
            <div className="md:hidden flex items-center">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="text-gray-600 hover:text-red-500"
              >
                <Menu className="h-6 w-6" />
              </button>
            </div>
          </div>
        </div>
        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a
                href="#"
                className="text-gray-600 hover:text-red-500 block px-3 py-2 rounded-md text-base font-medium"
              >
                Shop
              </a>
              <a
                href="#"
                className="text-gray-600 hover:text-red-500 block px-3 py-2 rounded-md text-base font-medium"
              >
                Play
              </a>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToPricing();
                  setIsMenuOpen(false);
                }}
                className="text-gray-600 hover:text-red-500 block px-3 py-2 rounded-md text-base font-medium"
              >
                Pricing
              </a>
              {user ? (
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDashboard();
                    setIsMenuOpen(false);
                  }}
                  className="bg-gradient-to-r from-red-400 to-pink-400 text-white hover:from-red-500 hover:to-pink-500 block px-3 py-2 rounded-md text-base font-medium"
                >
                  Dashboard
                </a>
              ) : (
                <>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAuth("login");
                      setIsMenuOpen(false);
                    }}
                    className="text-red-500 border border-red-500 hover:bg-red-500 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Login
                  </a>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      handleAuth("signup");
                      setIsMenuOpen(false);
                    }}
                    className="bg-gradient-to-r from-red-400 to-pink-400 text-white hover:from-red-500 hover:to-pink-500 block px-3 py-2 rounded-md text-base font-medium"
                  >
                    Sign Up
                  </a>
                </>
              )}
            </div>
          </div>
        )}
      </nav>

      {/* Main Content */}
      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* New Heading */}
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
          Hello Creators 👋
        </h1>

        {/* Hero Section */}
        <section className="bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg text-gray-800 py-10 sm:py-20">
          <div className="container mx-auto px-1 sm:px-4">
            <div className="flex flex-col items-center justify-between">
              <div className="w-full mb-10 text-center">
                <h2
                  style={{
                    fontFamily: '"VT323", Pacifico',
                    letterSpacing: "-0.02em",
                    fontWeight: "500",
                  }}
                  className="text-4xl sm:text-4xl lg:text-6xl mb-4 leading-tight w-full py-0 px-0 sm:py-6 sm:px-4 md:w-full"
                >
                  REACH YOUR NEXT MILESTONE OF{" "}
                  <span className="text-white font-extrabold bg-gradient-to-r from-red-500 to-pink-500 px-2 py-1 rounded-md">
                    {rotatingText}
                  </span>{" "}
                  AT GOD SPEED ⚡️
                </h2>
                <p className="text-lg sm:text-xl mb-8 text-gray-600 font-extrabold">
                  Acquire New Followers 🚀. Improve Your Engagement 💯.
                </p>
                <button className="bg-gradient-to-r from-purple-400 to-pink-400 hover:from-purple-500 hover:to-pink-500 text-white font-bold py-3 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105 flex items-center mx-auto">
                  <Play size={20} className="mr-2" />
                  Watch Demo
                </button>
              </div>
              <div className="w-full max-w-2xl">
                <div
                  className="relative rounded-lg overflow-hidden shadow-2xl"
                  style={{ paddingBottom: "56.25%" }}
                >
                  <iframe
                    src="https://www.youtube.com/embed/tAP_jZaYlzI"
                    title="Pikvin Explainer Video"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="absolute top-0 left-0 w-full h-full"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="mb-16">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Features
          </h2>
          <div className="grid md:grid-cols-3 gap-8">
            {[
              {
                icon: <Users className="h-12 w-12 text-blue-500" />,
                title: "Increase in Followers",
                description: "Grow your audience exponentially!",
              },
              {
                icon: <Zap className="h-12 w-12 text-yellow-500" />,
                title: "Improved Engagement",
                description: "Boost interaction with weekly pikvin giveaways !",
              },
              {
                icon: <Smile className="h-12 w-12 text-green-500" />,
                title: "Happy Followers",
                description: "Create a loyal and satisfied following!",
              },
            ].map((feature, index) => (
              <div
                key={index}
                className="bg-white border-none shadow-lg hover:shadow-xl transition-shadow duration-300 rounded-lg p-6"
              >
                <div className="flex justify-center">{feature.icon}</div>
                <h3 className="text-xl font-bold text-center mt-4">
                  {feature.title}
                </h3>
                <p className="text-center text-gray-600 mt-2">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </section>

        <section className="py-12 bg-gradient-to-r from-purple-100 to-pink-100">
          <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
              Start Growing Your Audience for Free
            </h2>
            <div className="bg-white rounded-lg shadow-xl overflow-hidden">
              <div className="px-6 py-8 sm:p-10 sm:pb-6">
                <div className="flex justify-center">
                  <span className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600">
                    Influencer Free Tier
                  </span>
                </div>
                <div className="mt-4 flex justify-center">
                  <span className="text-5xl font-extrabold text-gray-900">
                    $0
                  </span>
                  <span className="ml-1 text-xl font-semibold text-gray-500 self-end">
                    /month
                  </span>
                </div>
                <p className="mt-5 text-lg text-gray-500 text-center">
                  Until you reach your milestone
                </p>
              </div>
              <div className="px-6 pt-6 pb-8 bg-gray-50 sm:px-10 sm:py-10">
                <div className="mb-8 p-4 bg-gradient-to-r from-yellow-100 to-yellow-200 rounded-lg shadow-inner">
                  <h3 className="text-xl font-bold text-gray-800 mb-2 flex items-center justify-center">
                    <Gift className="h-6 w-6 text-yellow-500 mr-2" />
                    We Sponsor Your Giveaways!
                  </h3>
                  <p className="text-gray-700 text-center">
                    Pikvin will sponsor giveaways to your followers, helping you
                    grow your audience without any cost to you.
                  </p>
                </div>
                <ul className="space-y-4">
                  {[
                    "Full access to Pikvin's features",
                    "Unlimited sponsored giveaways",
                    "Detailed analytics",
                    "Customer retargeting",
                    "Email support",
                  ].map((feature, index) => (
                    <li key={index} className="flex items-start">
                      <div className="flex-shrink-0">
                        <Check className="h-6 w-6 text-green-500" />
                      </div>
                      <p className="ml-3 text-base text-gray-700">{feature}</p>
                    </li>
                  ))}
                </ul>
                <div className="mt-8">
                  <div className="rounded-lg shadow-md">
                    <a
                      href="#"
                      className="block w-full text-center rounded-lg border border-transparent bg-indigo-600 px-6 py-4 text-xl leading-6 font-medium text-white hover:bg-indigo-700 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150"
                    >
                      Start Growing Now
                    </a>
                  </div>
                </div>
                <div className="mt-6 text-center">
                  <p className="text-base font-medium text-gray-900 flex items-center justify-center">
                    <Zap className="h-5 w-5 text-yellow-500 mr-2" />
                    Upgrade anytime to unlock more features
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* How It Works Section */}
        {/* <section className="mb-16">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            How Pikvin Works
          </h2>
          <div className="flex justify-center items-center space-x-4">
            {[
              {
                icon: <Play className="h-8 w-8 text-green-500" />,
                text: "Play",
                bgColor: "bg-green-200",
              },
              {
                icon: <ShoppingCart className="h-8 w-8 text-blue-500" />,
                text: "Select Favorite Product",
                bgColor: "bg-blue-200",
              },
              {
                icon: <Mail className="h-8 w-8 text-purple-500" />,
                text: "Submit Email",
                bgColor: "bg-purple-200",
              },
              {
                icon: <Sparkles className="h-8 w-8 text-yellow-500" />,
                text: "Get Retargeted",
                bgColor: "bg-yellow-200",
              },
            ].map((item, index) => (
              <React.Fragment key={item.text}>
                <div className="text-center">
                  <div
                    className={`${item.bgColor} rounded-full p-4 inline-block`}
                  >
                    {item.icon}
                  </div>
                  <p className="mt-2">{item.text}</p>
                </div>
                {index < 3 && <div className="text-4xl text-gray-400">→</div>}
              </React.Fragment>
            ))}
          </div>
        </section> */}
        {/* <section className="mb-16 bg-gradient-to-r from-purple-100 to-pink-100 rounded-lg shadow-lg p-8">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
            Unlock Your Audience's Potential
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center gap-8">
            <div className="flex-1 text-center md:text-left">
              <h3 className="text-2xl font-semibold mb-4 text-purple-700">
                <span>Got a </span>
                <span className="relative">
                  <span className="absolute -inset-1 bg-gradient-to-r from-purple-400 to-pink-400 blur opacity-25"></span>
                  <span className="relative text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-pink-600">
                    {rotatingText}
                  </span>
                </span>
                <span>?</span>
              </h3>
              <p className="text-lg text-gray-700 mb-6">
                You're sitting on a <GoldMineText>Gold Mine</GoldMineText>!
                Pikvin's giveaway feature encourages customers to choose their
                favorite products from your entire catalog. This data is then
                used for valuable retargeting, driving sales and boosting your
                revenue.
              </p>
              <button className="bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 px-6 rounded-full text-lg font-semibold hover:from-purple-600 hover:to-pink-600 transition duration-300 cursor-pointer custom-cursor custom-cursor">
                Start Converting Today
              </button>
            </div>
            <div className="flex-1 flex justify-center">
              <div className="relative">
                <div className="absolute -inset-1 bg-gradient-to-r from-purple-600 to-pink-600 rounded-lg blur opacity-25"></div>
                <div className="relative bg-white p-6 rounded-lg shadow-xl">
                  <Star className="h-16 w-16 text-yellow-400 mx-auto mb-4" />
                  <h4 className="text-xl font-bold text-center mb-2">
                    Unleash the Power
                  </h4>
                  <ul className="space-y-2">
                    {[
                      "Convert followers to customers",
                      "Highly Targeted Retargeting",
                      "Customers Browse Your Whole Product Catalogue",
                      "Boost your ROI effortlessly",
                    ].map((item, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="h-5 w-5 text-green-500 mr-2 flex-shrink-0" />
                        <span>{item}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <InfluencerOptIn />
      </main>
      {/* Footer */}
      <footer className="bg-gradient-to-r from-red-300 to-red-500 text-white py-10 mt-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
            <div className="flex items-center space-x-2">
              <span role="img" aria-label="Penguin" className="text-3xl">
                🐧
              </span>
              <span className="text-2xl font-bold">Pikvin</span>
            </div>
            <div className="text-center text-sm order-3 md:order-2">
              © 2024 Pikvin. All rights reserved.
            </div>
            <div className="flex flex-wrap justify-center md:justify-end space-x-4 md:space-x-6 order-2 md:order-3">
              {["About", "Blog", "Support", "Terms", "Privacy"].map((item) => (
                <a
                  key={item}
                  href="#"
                  className="hover:text-white hover:underline mb-2 md:mb-0"
                >
                  {item}
                </a>
              ))}
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomeAlt;
