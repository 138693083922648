import React, { useState } from "react";
import { Box } from "@mui/material";

const CategoryMenu = ({ categories, onCategoryChange }) => {
  const [activeCategory, setActiveCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    onCategoryChange(category);
  };

  return (
    <Box
      component="nav"
      sx={{
        width: "100%",
        backgroundColor: "#f0f0f0",
        padding: "10px 0",
        overflowX: "auto",
        "&::-webkit-scrollbar": {
          height: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#888",
          borderRadius: "3px",
        },
      }}
    >
      <ul
        style={{
          listStyle: "none",
          display: "flex",
          padding: 0,
          margin: 0,
          whiteSpace: "nowrap",
        }}
      >
        {["All", ...categories].map((category) => (
          <li key={category} style={{ margin: "0 5px" }}>
            <button
              onClick={() => handleCategoryClick(category)}
              style={{
                padding: "8px 16px",
                border: "none",
                borderRadius: "20px",
                backgroundColor:
                  activeCategory === category ? "#007bff" : "transparent",
                color: activeCategory === category ? "white" : "#333",
                cursor: "pointer",
                transition: "all 0.3s ease",
                fontWeight: activeCategory === category ? "bold" : "normal",
                outline: "none",
              }}
            >
              {category}
            </button>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default CategoryMenu;
