import React, { useState } from "react";
import {
  Star,
  Play,
  MousePointer,
  ShoppingCart,
  Instagram,
  Mail,
  X,
  AlertCircle,
} from "lucide-react";
import { Alert, AlertTitle } from "@mui/material";

const CampaignTypeIcon = ({ type }) => {
  const IconComponent = type === "Instagram" ? Instagram : Mail;
  const backgroundColor = type === "Instagram" ? "bg-pink-500" : "bg-red-500";

  return (
    <div className={`${backgroundColor} rounded-full p-3 text-white`}>
      <IconComponent size={24} />
    </div>
  );
};

const StatBox = ({ icon: Icon, value, label }) => (
  <div className="flex items-center mb-2">
    <Icon className="text-blue-500 mr-2" size={20} />
    <span className="font-semibold text-lg">{value.toLocaleString()}</span>
    <span className="text-gray-600 ml-1">{label}</span>
  </div>
);

const HeroProductDialog = ({ isOpen, onClose, heroProducts }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div className="bg-white rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-xl font-semibold">Hero Products</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X size={24} />
          </button>
        </div>
        <div className="p-4">
          {heroProducts && heroProducts.length > 0 ? (
            <ul className="space-y-4">
              {heroProducts.map((product, index) => (
                <li
                  key={index}
                  className="flex items-center bg-gray-100 rounded-md p-2"
                >
                  <div className="flex-shrink-0 mr-4">
                    <img
                      src={product.image || "/api/placeholder/80/80"}
                      alt={product.name}
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  </div>
                  <div className="flex-grow">
                    <div className="flex items-center">
                      <Star className="text-yellow-500 mr-2" size={20} />
                      <span className="font-semibold">{product.name}</span>
                    </div>
                    {product.description && (
                      <p className="text-sm text-gray-600 mt-1">
                        {product.description}
                      </p>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <Alert>
              <AlertCircle className="h-4 w-4" />
              <AlertTitle>No hero products available</AlertTitle>
              <p>
                There are currently no hero products associated with this
                campaign.
              </p>
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

const CampaignCard = ({ campaign, onShowHeroProducts }) => (
  <div className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-shadow duration-300 overflow-hidden">
    <div className="p-6">
      <div className="flex items-center mb-4">
        <CampaignTypeIcon type={campaign.type} />
        <div className="ml-4">
          <h3 className="font-bold text-lg">{campaign.name}</h3>
          <p className="text-gray-600 text-sm">Started: {campaign.startDate}</p>
        </div>
      </div>

      <StatBox icon={Play} value={campaign.totalPlays} label="Plays" />
      <StatBox
        icon={MousePointer}
        value={campaign.totalClicks}
        label="Clicks"
      />
      <StatBox icon={ShoppingCart} value={campaign.totalSales} label="Sales" />

      <button
        onClick={() => onShowHeroProducts(campaign.heroProducts)}
        className="mt-4 w-full bg-indigo-600 text-white py-2 px-4 rounded-md hover:bg-indigo-700 transition-colors duration-300 flex items-center justify-center"
      >
        <Star className="mr-2" size={20} />
        Show Hero Products
      </button>
    </div>
  </div>
);

const MyCampaignsCard = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedHeroProducts, setSelectedHeroProducts] = useState([]);

  // Dummy data for testing (same as before)
  const dummyCampaigns = [
    {
      id: 1,
      name: "Summer Sale",
      type: "Instagram",
      startDate: "2023-06-01",
      totalPlays: 10000,
      totalClicks: 5000,
      totalSales: 1000,
      heroProducts: [
        {
          name: "Sunglasses",
          image: "/api/placeholder/150/150",
          description: "Stylish UV-protected sunglasses for the beach",
        },
        {
          name: "Beach Towel",
          image: "/api/placeholder/150/150",
          description: "Large, absorbent beach towel with tropical print",
        },
        {
          name: "Sunscreen",
          image: "/api/placeholder/150/150",
          description: "SPF 50 waterproof sunscreen for all-day protection",
        },
      ],
    },
    {
      id: 2,
      name: "Back to School",
      type: "Email",
      startDate: "2023-08-15",
      totalPlays: 8000,
      totalClicks: 3000,
      totalSales: 500,
      heroProducts: [
        {
          name: "Backpack",
          image: "/api/placeholder/150/150",
          description: "Durable backpack with multiple compartments",
        },
        {
          name: "Notebooks",
          image: "/api/placeholder/150/150",
          description: "Set of 5 spiral-bound notebooks, college-ruled",
        },
        {
          name: "Pens",
          image: "/api/placeholder/150/150",
          description: "Pack of 12 smooth-writing ballpoint pens",
        },
      ],
    },
    {
      id: 3,
      name: "Holiday Special",
      type: "Instagram",
      startDate: "2023-12-01",
      totalPlays: 15000,
      totalClicks: 7500,
      totalSales: 2000,
      heroProducts: [
        {
          name: "Christmas Tree",
          image: "/api/placeholder/150/150",
          description: "6-foot artificial pine tree with stand",
        },
        {
          name: "Ornaments",
          image: "/api/placeholder/150/150",
          description: "Set of 24 assorted glass ornaments in various colors",
        },
        {
          name: "Gift Wrap",
          image: "/api/placeholder/150/150",
          description: "Premium holiday-themed gift wrapping paper, 3 rolls",
        },
      ],
    },
  ];

  const handleShowHeroProducts = (heroProducts) => {
    setSelectedHeroProducts(heroProducts || []);
    setOpenDialog(true);
  };

  return (
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {dummyCampaigns.map((campaign) => (
          <CampaignCard
            key={campaign.id}
            campaign={campaign}
            onShowHeroProducts={handleShowHeroProducts}
          />
        ))}
      </div>
      <HeroProductDialog
        isOpen={openDialog}
        onClose={() => setOpenDialog(false)}
        heroProducts={selectedHeroProducts}
      />
    </div>
  );
};

export default MyCampaignsCard;
