import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
  Typography,
  CircularProgress,
  Chip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { fetchSubscribers, fetchProductById, fetchPlays } from "../../Utils";
import { useBrand } from "../../BrandContext";

const Customers = () => {
  const [subscribers, setSubscribers] = useState([]);
  const [loading, setLoading] = useState(true);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { brandName } = useBrand();

  useEffect(() => {
    const fetchData = async () => {
      if (brandName) {
        try {
          const subscribersList = await fetchPlays(brandName);
          const updatedSubscribersList = await Promise.all(
            subscribersList.map(async (subscriber) => {
              const product = await fetchProductById(
                subscriber.productId,
                brandName
              );
              return {
                ...subscriber,
                productUrl: product ? product.url : "N/A",
              };
            })
          );
          setSubscribers(updatedSubscribersList);
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    fetchData();
  }, [brandName]);

  const tableHeaderStyle = {
    backgroundColor: "#f5f5f5",
    fontWeight: "bold",
    color: "#333",
    padding: isSmallScreen ? "8px 4px" : "16px",
  };

  const tableRowStyle = {
    "&:nth-of-type(odd)": {
      backgroundColor: "#fafafa",
    },
    "&:hover": {
      backgroundColor: "#f0f0f0",
    },
  };

  const tableCellStyle = {
    padding: isSmallScreen ? "8px 4px" : "16px",
    fontSize: isSmallScreen ? "0.8rem" : "1rem",
  };

  const renderTableContent = () => {
    if (isSmallScreen) {
      return subscribers.map((subscriber, index) => (
        <TableRow key={index} style={tableRowStyle}>
          <TableCell style={tableCellStyle}>
            <Typography variant="subtitle2">ID: {index + 1}</Typography>
            <Typography variant="body2">
              Email: {subscriber.userEmail}
            </Typography>
            <Typography variant="body2">Name: {subscriber.userName}</Typography>
            <Typography variant="body2">
              Product:{" "}
              <Link
                href={subscriber.productUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </Link>
            </Typography>
            <Typography variant="body2">
              Phone: {subscriber.userPhNo || "---"}
            </Typography>
            <Typography variant="body2">
              Campaign: {subscriber.campaignName}
            </Typography>
            <Typography variant="body2">
              Coupon: {subscriber.couponCode}
            </Typography>
            <Typography variant="body2">
              Discount: {subscriber.discountPercentage}%
            </Typography>
            <Typography variant="body2">
              Date: {new Date(subscriber.dateTime).toLocaleDateString()}
            </Typography>
          </TableCell>
        </TableRow>
      ));
    } else {
      return subscribers.map((subscriber, index) => (
        <TableRow key={index} style={tableRowStyle}>
          <TableCell style={tableCellStyle}>{index + 1}</TableCell>
          <TableCell style={tableCellStyle}>{subscriber.userEmail}</TableCell>
          <TableCell style={tableCellStyle}>{subscriber.userName}</TableCell>
          <TableCell style={tableCellStyle}>
            <Link
              href={subscriber.productUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View Product
            </Link>
          </TableCell>
          <TableCell style={tableCellStyle}>
            {subscriber.userPhNo || "---"}
          </TableCell>
          <TableCell style={tableCellStyle}>
            {subscriber.campaignName}
          </TableCell>
          <TableCell style={tableCellStyle}>{subscriber.couponCode}</TableCell>
          <TableCell style={tableCellStyle}>
            {subscriber.discountPercentage}%
          </TableCell>
          <TableCell style={tableCellStyle}>
            {new Date(subscriber.dateTime).toLocaleDateString()}
          </TableCell>
        </TableRow>
      ));
    }
  };

  if (!brandName) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <div style={{ padding: isSmallScreen ? "12px" : "24px" }}>
      <Typography
        variant={isSmallScreen ? "h5" : "h4"}
        gutterBottom
        style={{ marginBottom: isSmallScreen ? "12px" : "24px", color: "#333" }}
      >
        Customer List
      </Typography>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            padding: isSmallScreen ? "24px" : "48px",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            overflowX: "auto",
          }}
        >
          <Table
            sx={{ minWidth: isSmallScreen ? 300 : 650 }}
            aria-label="customers table"
          >
            <TableHead>
              {!isSmallScreen && (
                <TableRow>
                  <TableCell style={tableHeaderStyle}>ID</TableCell>
                  <TableCell style={tableHeaderStyle}>Email</TableCell>
                  <TableCell style={tableHeaderStyle}>Name</TableCell>
                  <TableCell style={tableHeaderStyle}>Product</TableCell>
                  <TableCell style={tableHeaderStyle}>Phone Number</TableCell>
                  <TableCell style={tableHeaderStyle}>Campaign</TableCell>
                  <TableCell style={tableHeaderStyle}>Coupon</TableCell>
                  <TableCell style={tableHeaderStyle}>Discount</TableCell>
                  <TableCell style={tableHeaderStyle}>Date</TableCell>
                </TableRow>
              )}
            </TableHead>
            <TableBody>{renderTableContent()}</TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default Customers;
