import React, { useState, useEffect } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Link,
  IconButton,
  Chip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import YouTubeIcon from "@mui/icons-material/YouTube";
import SmsIcon from "@mui/icons-material/Sms";
import AddIcon from "@mui/icons-material/Add";
import MyCampaignsCard from "./MyCampaignsCard";

const campaigns = [
  {
    name: "Instagram",
    url: "https://www.instagram.com",
    icon: InstagramIcon,
    color: "#E1306C",
    available: true,
  },
  {
    name: "Email",
    url: "mailto:example@example.com",
    icon: EmailIcon,
    color: "#D44638",
    available: true,
  },
  {
    name: "WhatsApp",
    url: "https://wa.me/1234567890",
    icon: WhatsAppIcon,
    color: "#25D366",
    available: false,
  },
  {
    name: "YouTube",
    url: "https://www.youtube.com",
    icon: YouTubeIcon,
    color: "#FF0000",
    available: false,
  },
  {
    name: "SMS",
    url: "sms:+1234567890",
    icon: SmsIcon,
    color: "#4285F4",
    available: false,
  },
];

const myCampaigns = [
  {
    name: "Summer Sale 2023",
    startDate: "2023-06-01",
    type: "Instagram",
    totalPlays: 10000,
    totalClicks: 5000,
    totalSales: 500,
    heroProduct: "Summer Dress",
  },
  {
    name: "Back to School",
    startDate: "2023-08-15",
    type: "Email",
    totalPlays: 50000,
    totalClicks: 15000,
    totalSales: 2000,
    heroProduct: "Backpack",
  },
  {
    name: "Holiday Special",
    startDate: "2023-12-01",
    type: "Instagram",
    totalPlays: 25000,
    totalClicks: 10000,
    totalSales: 1500,
    heroProduct: "Gift Set",
  },
];

const Campaigns = () => {
  const [open, setOpen] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [marketingType, setMarketingType] = useState("");
  const [medium, setMedium] = useState("");
  const [showCampaignSelection, setShowCampaignSelection] = useState(false);

  const handleOpen = (campaign) => {
    setSelectedCampaign(campaign);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCampaignName("");
    setMarketingType("");
    setMedium("");
  };

  const createUniqueCampaignName = (
    campaignName,
    marketingType,
    medium,
    platform
  ) => {
    const timestamp = new Date().getTime();
    return `${campaignName}_${marketingType}_${medium}_${platform}_${timestamp}`;
  };

  const handleSubmit = () => {
    const uniqueCampaignName = createUniqueCampaignName(
      campaignName,
      marketingType,
      medium,
      selectedCampaign.name
    );
    console.log({
      campaignName: uniqueCampaignName,
      marketingType,
      medium,
      platform: selectedCampaign.name,
    });
    handleClose();
  };

  const toggleCampaignSelection = () => {
    setShowCampaignSelection(!showCampaignSelection);
  };

  return (
    <div
      style={{
        padding: "24px",
        backgroundColor: "#f5f5f5",
        minHeight: "100vh",
      }}
    >
      {!showCampaignSelection && (
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={toggleCampaignSelection}
          style={{
            marginBottom: "24px",
            padding: "16px 32px",
            fontSize: "1.2rem",
          }}
        >
          Add New Campaign
        </Button>
      )}

      {showCampaignSelection && (
        <>
          <Typography
            variant="h4"
            gutterBottom
            style={{
              marginBottom: "24px",
              color: "#333",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Select your Campaign
          </Typography>
          <Grid container spacing={3}>
            {campaigns.map((campaign, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <Card
                  style={{
                    borderRadius: "12px",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      transform: "translateY(-5px)",
                      boxShadow: "0 8px 16px rgba(0,0,0,0.2)",
                    },
                  }}
                >
                  <CardContent
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "24px",
                    }}
                  >
                    <IconButton
                      style={{
                        backgroundColor: campaign.color,
                        color: "white",
                        padding: "16px",
                        marginBottom: "16px",
                      }}
                    >
                      <campaign.icon style={{ fontSize: "32px" }} />
                    </IconButton>
                    <Typography
                      variant="h6"
                      style={{ marginBottom: "8px", fontWeight: "bold" }}
                    >
                      {campaign.name}
                    </Typography>
                    {campaign.available ? (
                      <Link
                        component="button"
                        onClick={() => handleOpen(campaign)}
                        style={{
                          textDecoration: "none",
                          color: campaign.color,
                          fontWeight: "medium",
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        Start {campaign.name} Campaign
                      </Link>
                    ) : (
                      <Chip
                        label="Coming Soon"
                        color="secondary"
                        style={{ marginTop: "8px" }}
                      />
                    )}
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </>
      )}

      <Typography
        variant="h4"
        gutterBottom
        style={{
          margin: "48px 0 24px",
          color: "#333",
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        My Campaigns
      </Typography>
      <MyCampaignsCard campaigns={myCampaigns} />

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create {selectedCampaign?.name} Campaign</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="campaign-name"
            label="Campaign Name"
            type="text"
            fullWidth
            variant="outlined"
            value={campaignName}
            onChange={(e) => setCampaignName(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel id="marketing-type-label">Type of Marketing</InputLabel>
            <Select
              labelId="marketing-type-label"
              id="marketing-type"
              value={marketingType}
              label="Type of Marketing"
              onChange={(e) => setMarketingType(e.target.value)}
            >
              <MenuItem value="Influencer">Influencer</MenuItem>
              <MenuItem value="My Own Channel">My Own Channel</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth margin="dense">
            <InputLabel id="medium-label">Medium</InputLabel>
            <Select
              labelId="medium-label"
              id="medium"
              value={medium}
              label="Medium"
              onChange={(e) => setMedium(e.target.value)}
            >
              <MenuItem value="Post">Post</MenuItem>
              <MenuItem value="Reel">Reel</MenuItem>
              <MenuItem value="Stories">Stories</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Create Campaign</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Campaigns;
