import React, { useState, useEffect } from "react";
import { AlertCircle } from "lucide-react";
import { fetchCoupons } from "../../Utils";
import { useBrand } from "../../BrandContext";
import { Timestamp } from "firebase/firestore"; // Add this import

const Coupons = () => {
  const [coupons, setCoupons] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [editData, setEditData] = useState({
    name: "",
    code: "",
    discount: "",
    expiryDate: "",
  });
  const [error, setError] = useState("");
  const { brandName } = useBrand();

  const formatDate = (timestamp) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleDateString();
    }
    return timestamp;
  };

  useEffect(() => {
    const loadCoupons = async () => {
      if (brandName) {
        const fetchedCoupons = await fetchCoupons(brandName);
        setCoupons(fetchedCoupons);
      }
    };
    loadCoupons();
  }, [brandName]);

  const handleEdit = (coupon) => {
    setEditMode(coupon.id);
    setEditData({
      name: coupon.name,
      code: coupon.code,
      discount: coupon.discount,
      expiryDate:
        coupon.expiryDate instanceof Timestamp
          ? coupon.expiryDate.toDate().toISOString().split("T")[0]
          : coupon.expiryDate,
    });
  };

  const handleSave = (id) => {
    if (
      !editData.name ||
      !editData.code ||
      !editData.discount ||
      !editData.expiryDate
    ) {
      setError("All fields are required.");
      return;
    }
    const updatedCoupons = coupons.map((coupon) =>
      coupon.id === id
        ? {
            ...coupon,
            ...editData,
            expiryDate: Timestamp.fromDate(new Date(editData.expiryDate)),
          }
        : coupon
    );
    setCoupons(updatedCoupons);
    setEditMode(null);
    setError("");
  };

  const handleChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  if (!brandName) {
    return <div>Loading...</div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <h1 className="text-4xl font-bold text-center text-gray-800 mb-8">
        Special Coupons
      </h1>
      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4"
          role="alert"
        >
          <strong className="font-bold">Error!</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {coupons.map((coupon) =>
          editMode === coupon.id ? (
            <div
              key={coupon.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
            >
              <div className="p-6">
                <input
                  type="text"
                  name="name"
                  value={editData.name}
                  onChange={handleChange}
                  className="w-full p-2 mb-4 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Coupon Name"
                />
                <input
                  type="text"
                  name="code"
                  value={editData.code}
                  onChange={handleChange}
                  className="w-full p-2 mb-4 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Coupon Code"
                />
                <input
                  type="text"
                  name="discount"
                  value={editData.discount}
                  onChange={handleChange}
                  className="w-full p-2 mb-4 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Discount"
                />
                <input
                  type="date"
                  name="expiryDate"
                  value={editData.expiryDate}
                  onChange={handleChange}
                  className="w-full p-2 mb-4 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <p className="text-gray-600 mb-4">{coupon.description}</p>
                <button
                  className="w-full bg-green-500 text-white p-2 rounded hover:bg-green-600 transition duration-300"
                  onClick={() => handleSave(coupon.id)}
                >
                  Save Changes
                </button>
              </div>
            </div>
          ) : (
            <div
              key={coupon.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform hover:scale-105 transition duration-300"
            >
              <div className="p-6">
                <h2 className="text-2xl font-bold text-gray-800 mb-2">
                  {coupon.name}
                </h2>
                <p className="text-gray-600 mb-2">
                  Code: <span className="font-semibold">{coupon.code}</span>
                </p>
                <p className="text-gray-600 mb-2">
                  Expiry Date:{" "}
                  <span className="font-semibold">
                    {formatDate(coupon.expiryDate)}
                  </span>
                </p>
                <p className="text-gray-600 mb-4">{coupon.description}</p>
                <button
                  className="w-full bg-blue-500 text-white p-2 rounded hover:bg-blue-600 transition duration-300"
                  onClick={() => handleEdit(coupon)}
                >
                  Edit Coupon
                </button>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default Coupons;
