import React, { createContext, useState, useContext, useEffect } from "react";
import { getUserBrandName } from "./Utils";
import { auth } from "./firebase-config"; // Assuming you have this

const BrandContext = createContext();

export const useBrand = () => useContext(BrandContext);

export const BrandProvider = ({ children }) => {
  const [brandName, setBrandName] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const brand = await getUserBrandName(user.email);
        setBrandName(brand);
      } else {
        setBrandName(null);
      }
    });

    return () => unsubscribe();
  }, []);

  return (
    <BrandContext.Provider value={{ brandName, setBrandName }}>
      {children}
    </BrandContext.Provider>
  );
};
