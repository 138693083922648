import React, { useState, useEffect } from "react";

const InstagramAutocomplete = ({ value, onChange }) => {
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    // This is a mock function. In a real application, you'd fetch suggestions from an API
    const mockFetchSuggestions = (input) => {
      const mockSuggestions = [
        "instagram",
        "instagood",
        "instadaily",
        "instatravel",
        "instafood",
        "instagrammers",
        "instapic",
        "instafollow",
        "instacool",
        "instafit",
      ];
      return mockSuggestions.filter((s) => s.startsWith(input.toLowerCase()));
    };

    if (value.length > 1) {
      setSuggestions(mockFetchSuggestions(value.slice(1)));
    } else {
      setSuggestions([]);
    }
  }, [value]);

  return (
    <div className="relative">
      <input
        className="w-full px-4 py-4 text-lg border-2 border-indigo-500 rounded-lg focus:ring-2 focus:ring-indigo-600 bg-white shadow-md"
        placeholder="Your Instagram Handle"
        type="text"
        value={value}
        onChange={(e) =>
          onChange(
            e.target.value.startsWith("@")
              ? e.target.value
              : "@" + e.target.value
          )
        }
        required
      />
      {suggestions.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg mt-1 max-h-40 overflow-y-auto">
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
              onClick={() => onChange("@" + suggestion)}
            >
              @{suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

const InfluencerOptIn = () => {
  const [instagram, setInstagram] = useState("@");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log({ instagram, name, email });
  };

  return (
    <section className="py-12">
      <div className="max-w-lg mx-auto bg-gradient-to-br from-purple-200 to-blue-200 shadow-xl rounded-xl overflow-hidden">
        <div className="p-8">
          <h2 className="text-3xl font-bold text-center text-indigo-900 mb-6">
            Join Our Community
          </h2>
          <form className="space-y-6" onSubmit={handleSubmit}>
            <div className="space-y-2">
              <label
                htmlFor="instagram"
                className="block text-sm font-medium text-indigo-900"
              >
                Instagram Handle (Required)
              </label>
              <InstagramAutocomplete
                value={instagram}
                onChange={setInstagram}
              />
            </div>
            <input
              className="w-full px-4 py-3 border-0 rounded-lg focus:ring-2 focus:ring-indigo-600 bg-white shadow-sm"
              placeholder="Your Name"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              className="w-full px-4 py-3 border-0 rounded-lg focus:ring-2 focus:ring-indigo-600 bg-white shadow-sm"
              placeholder="Your Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              type="submit"
              className="w-full py-3 px-4 rounded-lg text-lg font-semibold bg-gradient-to-r from-indigo-500 to-purple-500 text-white hover:from-indigo-600 hover:to-purple-600 transition-colors duration-300"
            >
              Sign Up
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default InfluencerOptIn;
