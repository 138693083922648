import React, { useState, useEffect } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  ListItemIcon,
  IconButton,
  useTheme,
  useMediaQuery,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import PeopleIcon from "@mui/icons-material/People";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import BookIcon from "@mui/icons-material/Book";
import DescriptionIcon from "@mui/icons-material/Description";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LogoutIcon from "@mui/icons-material/Logout";
import TableChartIcon from "@mui/icons-material/TableChart";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase-config";
import Swal from "sweetalert2";
import ProductTable from "../../Components/ProductTable/ProductTable";
import { getUserBrandName, addUserToBrandUsers } from "../../Utils";
import { Campaign } from "@mui/icons-material";

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    backgroundColor: "#282c34",
    color: "white",
  },
}));

const Dashboard = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [openBrandDialog, setOpenBrandDialog] = useState(false);
  const [brandName, setBrandName] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  useEffect(() => {
    const checkBrandName = async () => {
      const user = auth.currentUser;
      if (user) {
        const userBrandName = await getUserBrandName(user.email);
        if (!userBrandName) {
          setOpenBrandDialog(true);
        }
      }
    };

    checkBrandName();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out of your account.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out!",
      background: "#f8f9fa",
      borderRadius: "10px",
      customClass: {
        title: "text-xl font-bold text-gray-800",
        content: "text-gray-600",
        confirmButton:
          "px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors duration-200",
        cancelButton:
          "px-4 py-2 text-gray-700 bg-gray-200 hover:bg-gray-300 rounded-lg transition-colors duration-200",
      },
    });

    if (result.isConfirmed) {
      try {
        await auth.signOut();
        Swal.fire({
          title: "Logged Out!",
          text: "You have been successfully logged out.",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
        navigate("/auth");
      } catch (error) {
        console.error("Error signing out: ", error);
        Swal.fire({
          title: "Error",
          text: "An error occurred while logging out.",
          icon: "error",
        });
      }
    }
  };

  const handleAddBrandName = async () => {
    if (!brandName.trim()) {
      Swal.fire({
        icon: "error",
        title: "Brand Name Required",
        text: "Please enter a valid brand name to continue.",
      });
      return;
    }

    try {
      await addUserToBrandUsers(brandName, auth.currentUser.email);
      setOpenBrandDialog(false);
      Swal.fire({
        icon: "success",
        title: "Brand Name Added",
        text: "Your brand name has been successfully added!",
        showConfirmButton: false,
        timer: 1500,
      });
    } catch (error) {
      console.error("Error adding brand name:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while adding your brand name. Please try again.",
      });
    }
  };

  const handleBrandNameChange = (e) => {
    setBrandName(e.target.value);
  };

  const drawer = (
    <Box>
      <Typography
        variant="h6"
        sx={{ my: 2, textAlign: "center", color: "white" }}
      >
        Dashboard
      </Typography>
      <List>
        {[
          { text: "Overview", icon: <HomeIcon /> },
          { text: "Analytics", icon: <AnalyticsIcon /> },
          { text: "Customers", icon: <PeopleIcon /> },
          { text: "Coupons", icon: <LocalOfferIcon /> },
          { text: "Campaigns", icon: <Campaign /> },
          { text: "Templates", icon: <DescriptionIcon /> },
          { text: "Settings", icon: <SettingsIcon /> },
          { text: "Contact", icon: <ContactMailIcon /> },
          { text: "Product-Table", icon: <TableChartIcon /> },
        ].map((item, index) => (
          <ListItem
            button
            key={item.text}
            onClick={() => navigate(`/dashboard/${item.text.toLowerCase()}`)}
          >
            <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
      <Box sx={{ position: "absolute", bottom: 16, left: 16, right: 16 }}>
        <Button
          variant="contained"
          color="secondary"
          startIcon={<LogoutIcon />}
          fullWidth
          onClick={handleLogout}
        >
          Logout
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{
          mr: 3,
          display: { sm: "none" },
          height: "50px",
          width: "50px",
          position: "fixed",
        }}
      >
        <MenuIcon />
      </IconButton>
      <StyledDrawer
        variant={isMobile ? "temporary" : "permanent"}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        anchor="left"
      >
        {drawer}
      </StyledDrawer>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          backgroundColor: "#f0f2f5",
          pt: 8,
          minHeight: "100vh",
          overflow: "auto",
        }}
      >
        {children}
      </Box>
      <Dialog
        open={openBrandDialog}
        onClose={() => {}} // Prevent closing by clicking outside
        PaperProps={{
          style: {
            borderRadius: "15px",
            padding: "20px",
            boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
          },
        }}
      >
        <DialogTitle
          sx={{
            textAlign: "center",
            color: "#333",
            fontSize: "1.5rem",
            fontWeight: "bold",
          }}
        >
          Welcome to Your Dashboard!
        </DialogTitle>
        <DialogContent>
          <Typography
            variant="body1"
            sx={{ mb: 3, textAlign: "center", color: "#666" }}
          >
            To get started, please enter your brand name. This is required for
            the portal to function properly.
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            id="brandName"
            label="Brand Name"
            type="text"
            fullWidth
            variant="outlined"
            value={brandName}
            onChange={handleBrandNameChange}
            sx={{
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: "#3f51b5",
                },
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", mt: 2 }}>
          <Button
            onClick={handleAddBrandName}
            variant="contained"
            sx={{
              bgcolor: "#3f51b5",
              color: "white",
              "&:hover": {
                bgcolor: "#303f9f",
              },
              borderRadius: "25px",
              padding: "10px 30px",
            }}
          >
            Add Brand Name
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
