import React, { useState } from "react";
import * as XLSX from "xlsx";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Link,
} from "@mui/material";

const ProductTable = () => {
  const [products, setProducts] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const formattedData = jsonData.slice(1).map((row) => ({
        url: row[0],
        productId: row[1],
        category: row[2],
        productName: row[9],
        costPrice: row[11],
        sellingPrice: row[12],
        available: row[13],
        productPageUrl: row[14],
        imageUrl: row[15],
        plainTextDescription: row[17],
      }));

      setProducts(formattedData);
    };

    reader.readAsArrayBuffer(file);
  };

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Product Table
      </Typography>
      <Button variant="contained" component="label" sx={{ marginBottom: 2 }}>
        Upload File
        <input
          type="file"
          accept=".xlsx, .xls"
          hidden
          onChange={handleFileUpload}
        />
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="product table">
          <TableHead>
            <TableRow>
              <TableCell>URL</TableCell>
              <TableCell>Product ID</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Product Name</TableCell>
              <TableCell>Cost Price</TableCell>
              <TableCell>Selling Price</TableCell>
              <TableCell>Available</TableCell>
              <TableCell>Product Page URL</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Plain Text Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.url}</TableCell>
                <TableCell>{product.productId}</TableCell>
                <TableCell>{product.category}</TableCell>
                <TableCell>{product.productName}</TableCell>
                <TableCell>{product.costPrice}</TableCell>
                <TableCell>{product.sellingPrice}</TableCell>
                <TableCell>{product.available ? "Yes" : "No"}</TableCell>
                <TableCell>
                  <Link
                    href={product.productPageUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </Link>
                </TableCell>
                <TableCell>
                  <img
                    src={product.imageUrl}
                    alt={product.productName}
                    width="50"
                  />
                </TableCell>
                <TableCell>{product.plainTextDescription}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductTable;
