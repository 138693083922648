import React from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Chip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  transition: "transform 0.3s, box-shadow 0.3s",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: theme.shadows[10],
  },
}));

const CardMediaStyled = styled(CardMedia)({
  paddingTop: "56.25%", // 16:9 aspect ratio
  position: "relative",
  height: "365px",
});

const CategoryChip = styled(Chip)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  right: theme.spacing(1),
  backgroundColor: "rgba(255, 255, 255, 0.8)",
}));

const CardContentStyled = styled(CardContent)({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const PlayCount = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.secondary,
  marginTop: theme.spacing(1),
}));

const truncateTitle = (title, maxLength) => {
  if (title.length > maxLength) {
    return title.substring(0, maxLength) + "...";
  }
  return title;
};

const ProductCard = ({ product }) => {
  return (
    <StyledCard>
      <CardMediaStyled image={product.image} title={product.title}>
        <CategoryChip label={product.category} size="small" />
      </CardMediaStyled>
      <CardContentStyled>
        <Typography gutterBottom variant="h6" component="div">
          {truncateTitle(product.title, 20)}
        </Typography>
        <PlayCount>
          <PlayArrowIcon fontSize="small" style={{ marginRight: 4 }} />
          <Typography variant="body2">
            {product.totalPlays || 1} plays
          </Typography>
        </PlayCount>
      </CardContentStyled>
    </StyledCard>
  );
};

export default ProductCard;
