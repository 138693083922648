import React, { useState } from "react";
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import axios from "axios";

const Settings = () => {
  const [mailProvider, setMailProvider] = useState("");

  const handleMailProviderChange = (event) => {
    setMailProvider(event.target.value);
  };
  // const handleConnectClick = async () => {
  //   try {
  //     const provider = mailProvider ?? "None";
  //     const response = await axios.post("http://localhost:3000/Klaviyo", {
  //       mailProvider: provider,
  //     });
  //     console.log("Response:", response.data);
  //   } catch (error) {
  //     console.error("Error connecting to server:", error);
  //   }

  // };
  const handleConnectClick = () => {
    if (mailProvider === "klaviyo") {
      window.location.href = `http://localhost:3000/oauth/klaviyo/authorize`;
    } else {
      console.error("Unsupported mail provider");
    }
  };

  return (
    <div>
      <Typography variant="h4">Settings Content</Typography>
      <FormControl component="fieldset" sx={{ mt: 4, color: "black" }}>
        <FormLabel
          component="legend"
          sx={{
            color: "black",
            fontStyle: "italic",
            "&.Mui-focused": {
              color: "black",
            },
          }}
        >
          Which mail provider do you use?
        </FormLabel>
        <RadioGroup
          aria-label="mail-provider"
          name="mail-provider"
          value={mailProvider}
          onChange={handleMailProviderChange}
        >
          <FormControlLabel
            value="klaviyo"
            control={
              <Radio
                sx={{ color: "black", "&.Mui-checked": { color: "black" } }}
              />
            }
            label="Klaviyo"
          />
          <FormControlLabel
            value="mailchimp"
            control={
              <Radio
                sx={{ color: "black", "&.Mui-checked": { color: "black" } }}
              />
            }
            label="Mailchimp"
          />
          <FormControlLabel
            value="sendgrid"
            control={
              <Radio
                sx={{ color: "black", "&.Mui-checked": { color: "black" } }}
              />
            }
            label="SendGrid"
          />
        </RadioGroup>
        {mailProvider && (
          <Button
            variant="contained"
            sx={{
              mt: 2,
              bgcolor: "black",
              color: "white",
              "&:hover": {
                bgcolor: "black",
                transform: "scale(1.05)",
              },
            }}
            onClick={handleConnectClick}
          >
            Connect
          </Button>
        )}
      </FormControl>
    </div>
  );
};

export default Settings;
