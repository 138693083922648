import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  Paper,
  Box,
  Container,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DOMPurify from "dompurify";
import { fetchHtmlTemplates } from "../../Utils";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import EmailIcon from "@mui/icons-material/Email";

const Templates = () => {
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [editedHtml, setEditedHtml] = useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const cleanHtmlContent = (html) => {
    return html.replace(/\s+/g, " ").trim();
  };

  useEffect(() => {
    const fetchTemplates = async () => {
      const templates = await fetchHtmlTemplates("Random");
      if (templates) {
        const formattedTemplates = [
          {
            name: "Pikvin Giveaway Email",
            description: "This is a sample email template",
            htmlFile: cleanHtmlContent(templates.PikvinGiveaway),
          },
          {
            name: "Pikvin Retargeting",
            description: "This is a sample email template",
            htmlFile: cleanHtmlContent(templates.PikvinRetargeting),
          },
        ];
        setEmailTemplates(formattedTemplates);
        setSelectedTemplate(formattedTemplates[0]);
        setEditedHtml(formattedTemplates[0].htmlFile);
      }
    };

    fetchTemplates();
  }, []);

  const handleRequestForChanges = () => {
    const mailtoLink = `mailto:vedantgoyal867@gmail.com?subject=Request%20for%20Changes%20to%20${
      selectedTemplate.name
    }&body=${encodeURIComponent(editedHtml)}`;
    window.open(mailtoLink, "_blank");
  };

  const handleTemplateChange = (e) => {
    const newTemplate = emailTemplates.find(
      (template) => template.name === e.target.value
    );
    setSelectedTemplate(newTemplate);
    setEditedHtml(newTemplate.htmlFile);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(editedHtml).then(() => {
      alert("HTML code copied to clipboard!");
    });
  };

  const styles = {
    container: {
      padding: theme.spacing(3),
      backgroundColor: "#f5f5f5",
      minHeight: "100vh",
    },
    paper: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    },
    header: {
      marginBottom: theme.spacing(3),
      color: theme.palette.primary.main,
    },
    selectContainer: {
      marginBottom: theme.spacing(3),
    },
    previewContainer: {
      marginTop: theme.spacing(3),
    },
    previewHeader: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      padding: theme.spacing(1, 2),
      borderRadius: theme.shape.borderRadius,
      display: "inline-block",
      marginBottom: theme.spacing(2),
    },
    previewContent: {
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: "white",
      minHeight: "300px",
      overflowY: "auto",
    },
    button: {
      margin: theme.spacing(1, 0),
    },
  };

  if (isMobile) {
    return (
      <Container maxWidth="sm" style={styles.container}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ color: theme.palette.error.main }}
        >
          <strong>Important Notice:</strong> Please use your desktop to view
          this page for the best experience.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" style={styles.container}>
      <Paper style={styles.paper}>
        <Typography variant="h4" gutterBottom style={styles.header}>
          Email Template Editor
        </Typography>
        <Box style={styles.selectContainer}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Select Template</InputLabel>
            <Select
              value={selectedTemplate?.name || ""}
              onChange={handleTemplateChange}
              label="Select Template"
            >
              {emailTemplates.map((template) => (
                <MenuItem key={template.name} value={template.name}>
                  {template.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box style={styles.previewContainer}>
          <Typography variant="h6" style={styles.previewHeader}>
            Preview
          </Typography>
          <Box
            style={styles.previewContent}
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(editedHtml),
            }}
          />
        </Box>
        <Box display="flex" justifyContent="space-between" marginTop={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ContentCopyIcon />}
            onClick={handleCopyToClipboard}
            style={styles.button}
          >
            Copy HTML
          </Button>
          <Button
            variant="contained"
            color="secondary"
            startIcon={<EmailIcon />}
            onClick={handleRequestForChanges}
            style={styles.button}
          >
            Request Changes
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Templates;
