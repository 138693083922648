import { db } from "./firebase-config";
import {
  collection,
  doc,
  writeBatch,
  getDocs,
  getDoc,
  query,
  where,
  setDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

export const fetchProducts = async (brandName) => {
  const collectionRef = collection(db, "Brands", brandName, "Products"); // Path to the subcollection
  const q = query(collectionRef); // Create a query against the collection

  try {
    const querySnapshot = await getDocs(q);
    const productList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return productList;
  } catch (error) {
    console.error("Failed to fetch products:", error);
    return [];
  }
};
export const countSubscribersByProduct = async (brandName, productId) => {
  const subscribersCollectionRef = collection(
    db,
    "Brands",
    brandName,
    "Subscribers"
  );
  const q = query(
    subscribersCollectionRef,
    where("productSelected", "==", productId)
  );

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.size; // Return the count of documents
  } catch (error) {
    console.error("Failed to count subscribers:", error);
    return 0;
  }
};
export const fetchHtmlTemplates = async (brandName) => {
  const collectionRef = collection(db, "Brands", brandName, "HtmlTemplates");

  try {
    const querySnapshot = await getDocs(collectionRef);
    if (!querySnapshot.empty) {
      const docSnapshot = querySnapshot.docs[0]; // Assuming there's only one document in the collection
      const data = docSnapshot.data();
      console.log(data);
      return {
        PikvinGiveaway: data.PikvinGiveaway,
        PikvinRetargeting: data.PikvinRetargeting,
      };
    } else {
      console.error("No HTML templates found!");
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch HTML templates:", error);
    return null;
  }
};
export const fetchCategories = async (brandName) => {
  const collectionRef = collection(db, "Brands", brandName, "Categories"); // Path to the subcollection
  const q = query(collectionRef); // Create a query against the collection

  try {
    const querySnapshot = await getDocs(q);
    const categoriesList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    const categoryNames = categoriesList.map((category) => category.Name);
    console.log(categoryNames);
    return categoryNames;
  } catch (error) {
    console.error("Failed to fetch categories:", error);
    return [];
  }
};
export const fetchSubscribers = async (brandName) => {
  const collectionRef = collection(db, "Brands", brandName, "Subscribers"); // Path to the subcollection
  const q = query(collectionRef); // Create a query against the collection

  try {
    const querySnapshot = await getDocs(q);
    const subscribersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("--->", subscribersList);
    return subscribersList;
  } catch (error) {
    console.error("Failed to fetch subscribers:", error);
    return [];
  }
};
export const fetchPlays = async (brandName) => {
  const collectionRef = collection(db, "Brands", brandName, "Plays"); // Path to the subcollection
  const q = query(collectionRef); // Create a query against the collection

  try {
    const querySnapshot = await getDocs(q);
    const subscribersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("--->", subscribersList);
    return subscribersList;
  } catch (error) {
    console.error("Failed to fetch subscribers:", error);
    return [];
  }
};
export const fetchProductById = async (id, brandName) => {
  const collectionRef = collection(db, "Brands", brandName, "Products");
  const q = query(collectionRef, where("id", "==", id)); // Query to find the document with the given id field

  try {
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const productDoc = querySnapshot.docs[0]; // Assuming id is unique and only one document will be returned
      return { id: productDoc.id, ...productDoc.data() };
    } else {
      console.log(id, brandName);
      console.error("No such product!");
      return null;
    }
  } catch (error) {
    console.error("Failed to fetch product by ID:", error);
    return null;
  }
};

export const fetchCoupons = async (brandName) => {
  const collectionRef = collection(db, "Brands", brandName, "Coupons"); // Path to the subcollection
  const q = query(collectionRef); // Create a query against the collection

  try {
    const querySnapshot = await getDocs(q);
    const couponsList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return couponsList;
  } catch (error) {
    console.error("Failed to fetch coupons:", error);
    return [];
  }
};

export const addUserToBrandUsers = async (brandName, userEmail) => {
  try {
    const brandUsersRef = collection(db, "BrandOwners");
    await addDoc(brandUsersRef, {
      brandName,
      userEmail,
      registrationDate: serverTimestamp(),
    });
    console.log("User added to BrandOwners collection");
  } catch (error) {
    console.error("Error adding user to BrandOwners:", error);
    throw error;
  }
};
export const getUserBrandName = async (userEmail) => {
  try {
    const brandOwnersRef = collection(db, "BrandOwners");
    const q = query(brandOwnersRef, where("userEmail", "==", userEmail));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const brandOwnerDoc = querySnapshot.docs[0];
      const brandName = brandOwnerDoc.data().brandName;

      if (brandName) {
        return brandName;
      } else {
        console.log("User found, but no brandName field");
        return null;
      }
    } else {
      console.log("No brand owner found for this user");
      return null;
    }
  } catch (error) {
    console.error("Error fetching user's brand name:", error);
    throw error;
  }
};
export const countPlaysByCampaignName = async (brandName, campaignName) => {
  const playsCollectionRef = collection(db, "Brands", brandName, "Plays");
  const q = query(
    playsCollectionRef,
    where("campaignName", "==", campaignName)
  );

  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.size;
  } catch (error) {
    console.error("Failed to count plays by campaign name:", error);
    return 0;
  }
};
