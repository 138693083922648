import React, { useState } from "react";
import { TextField, Button, Typography, Box, Paper, Fade } from "@mui/material";
import { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { styled } from "@mui/material/styles";
import { motion } from "framer-motion";

const StyledPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .PhoneInputInput": {
    ...theme.typography.body1,
    padding: "18.5px 14px",
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
    width: "100%",
    boxSizing: "border-box",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:focus": {
      borderColor: theme.palette.primary.main,
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      borderColor: theme.palette.primary.main,
    },
    "&.Mui-focused": {
      boxShadow: `0 0 0 2px ${theme.palette.primary.light}`,
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 4),
  fontWeight: 600,
  transition: theme.transitions.create(["background-color", "box-shadow"]),
  "&:hover": {
    boxShadow: `0 4px 8px ${theme.palette.primary.light}`,
  },
}));

const MotionBox = styled(motion.div)({
  width: "100%",
});

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phone)) {
      alert("Please enter a valid phone number");
      return;
    }
    // Handle form submission logic here
    console.log("Name:", name);
    console.log("Email:", email);
    console.log("Phone:", phone);
    console.log("Message:", message);
    setSubmitted(true);
  };

  return (
    <Fade in={true} timeout={1000}>
      <Paper elevation={0} sx={{ borderRadius: 4, overflow: "hidden" }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 3,
            maxWidth: 500,
            margin: "0 auto",
            padding: 4,
            backgroundColor: "#f8f8f8",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: "#333",
              fontWeight: 700,
            }}
          >
            Contact Us
          </Typography>
          {!submitted ? (
            <>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <StyledTextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ backgroundColor: "white" }}
                />
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.3 }}
              >
                <StyledTextField
                  label="Email"
                  variant="outlined"
                  type="email"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  sx={{ backgroundColor: "white" }}
                />
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 }}
              >
                <StyledPhoneInput
                  placeholder="Enter phone number"
                  value={phone}
                  onChange={setPhone}
                  defaultCountry="US"
                  style={{ backgroundColor: "white" }}
                />
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
              >
                <StyledTextField
                  label="Message"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  sx={{ backgroundColor: "white" }}
                />
              </MotionBox>
              <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.6 }}
              >
                <StyledButton type="submit" variant="contained" color="primary">
                  Submit
                </StyledButton>
              </MotionBox>
            </>
          ) : (
            <MotionBox
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5 }}
            >
              <Typography
                variant="h5"
                sx={{ color: "#333", textAlign: "center" }}
              >
                Thank you for your message!
                <br />
                We'll get back to you soon.
              </Typography>
            </MotionBox>
          )}
        </Box>
      </Paper>
    </Fade>
  );
};

export default Contact;
