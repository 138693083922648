import React, { useEffect, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import {
  fetchProducts,
  countSubscribersByProduct,
  fetchCategories,
} from "../../Utils";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import ProductCard from "../ProductCard/ProductCard";
import CategoryMenu from "../CategoryMenu/CategoryMenu";
import { useBrand } from "../../BrandContext";

const Overview = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState("All");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const { brandName } = useBrand();

  useEffect(() => {
    const fetchData = async () => {
      if (brandName) {
        console.log("Fetching data for brand:", brandName);
        const productList = await fetchProducts(brandName);
        const productsWithPlays = await Promise.all(
          productList.map(async (product) => {
            const countedPlays = await countSubscribersByProduct(
              brandName,
              product.id
            );
            return { ...product, countedPlays };
          })
        );
        // Sort products by totalPlays in descending order
        const sortedProducts = productsWithPlays.sort(
          (a, b) => (b.totalPlays || 0) - (a.totalPlays || 0)
        );
        setProducts(sortedProducts);

        const categoryList = await fetchCategories(brandName);
        setCategories(categoryList);
      }
    };
    fetchData();
  }, [brandName]);

  const handleCategoryChange = (newCategory) => {
    setCategory(newCategory);
  };

  const filteredProducts =
    category === "All"
      ? products
      : products.filter((product) => product.category === category);

  if (!brandName) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box sx={{ width: "100%", overflowX: "hidden" }}>
      <CategoryMenu
        categories={categories}
        onCategoryChange={handleCategoryChange}
      />

      <Box sx={{ padding: "20px" }}>
        <CustomDatePicker
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />

        <Grid container spacing={2}>
          {filteredProducts.map((product, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <ProductCard product={product} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Overview;
